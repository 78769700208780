$ = jQuery.noConflict();
let windowSize = false;
let windowHeight= $(window).height();
let gutenbergScrollAnims = false;
let blockTriggerHeight = $(window).height()*.33;

jQuery(document).ready(function() {

//  if(jQuery('.single-slick-container').length() > 0) {

jQuery('.single-slick-container').slick({
    dots: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    prevArrow: jQuery('.slick-prev'),
    nextArrow: jQuery('.slick-next'),
  });

// };

if(jQuery('.sub-slick-container').length > 0) {
  //if has sub slick class
  jQuery('.sub-slick-container').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3500,
    asNavFor: '.single-slick-container'
  });
  jQuery('.single-slick-container').slick("slickSetOption","asNavFor", ".sub-slick-container");
};

  // NAVIGATION SCRIPT
	jQuery(function() {
    var height = jQuery('.nav-container-desktop').height();
     jQuery(window).scroll(function () {
       //IF LESS THAN HEIGHT OF NAV CONTAINER show fixed nav
        if (jQuery(this).scrollTop() >= 20) {
       jQuery('.nav-container-desktop').css('background-color', 'rgba(55, 55, 55, 0.9)');
       jQuery('.home .nav-container-desktop').css('background-color', 'rgba(55, 55, 55, 0.9)');
       jQuery('.home .nav-container-desktop').css('visibility', 'visible');
      } else if (jQuery(this).scrollTop() <= 0) {
        jQuery('.nav-container-desktop').css('background',  'none');
        jQuery('.home .nav-container-desktop').css('visibility', 'hidden');
      } 

     });
  });



      //SEARCH ICON
      jQuery('.search-icon, .search-icon-black').on('click', function(){
        jQuery('.search-container').addClass('active');
        jQuery('.nav-container-desktop .close-btn').show();
        jQuery('.nav-container-desktop .search-icon').hide();
    });

    //CLOSE BTN
    jQuery('.nav-container-desktop .close-btn').on('click', function(){
      jQuery('.search-container').removeClass('active');
      jQuery('.nav-container-desktop .close-btn').hide();
      jQuery('.nav-container-desktop .search-icon').show()
    });

  //MOBILE 
  //HAMBURGER MENU
  jQuery('.hamburger-menu').on('click', function() {
    jQuery('.menu-overlay').addClass('active');
  });

  //CLOSE BTN

  jQuery('.menu-overlay-container .close-btn').on('click', function(){
    jQuery('.menu-overlay').removeClass('active');
  });


 

   if ( jQuery('.gutenberg-styles > *').length && (!( window.location.hash != "" && jQuery(window.location.hash).length )) ) {
    gutenbergScrollAnims = true;
    jQuery('.gutenberg-styles > *').each(function(){
      let offset = jQuery(this).get(0).getBoundingClientRect().top;;
      jQuery(this).data('offset', offset);
      jQuery(this).addClass('to-reveal');
    });

    let numRevealed = 0;
    jQuery('.gutenberg-styles .to-reveal').each(function() {
      if (jQuery(window).scrollTop() + windowHeight - blockTriggerHeight >= jQuery(this).data('offset')) {
        numRevealed++;
        const thisRef = $(this);
        setTimeout(function(){
          thisRef.removeClass('to-reveal');
          thisRef.addClass('revealed');
        }, 700 + (numRevealed * 600));
      }
    });
  }
  


    var $grid = jQuery('.grid');

    $grid.imagesLoaded().progress( function() {
      $grid.packery({
        // options
        itemSelector: '.grid-item',
        gutter:'.gutter',
        percentPosition: true,
      });

    });




});

$(window).scroll(()=>{
  let scrolled = $(window).scrollTop();

  // gutenberg scroll anims
  if (gutenbergScrollAnims) {
   $('.gutenberg-styles .to-reveal').each(function() {
    console.log($(this).data('offset'));
    console.log(scrolled + windowHeight - blockTriggerHeight);
     if (scrolled + windowHeight - blockTriggerHeight >= $(this).data('offset')) {
       $(this).removeClass('to-reveal');
       $(this).addClass('revealed');

     }
   });
  }
});

$(window).resize(() => {

  if ( jQuery('.gutenberg-styles > *').length && (!( window.location.hash != "" && jQuery(window.location.hash).length )) ) {
    gutenbergScrollAnims = true;
    // console.log(window.scrollY + $(window).height());
    // console.log($('.gutenberg-styles > *'));
    jQuery('.gutenberg-styles > *').each(function(){
      let offset = jQuery(this).get(0).getBoundingClientRect().top;
      // console.log(offset);
      jQuery(this).data('offset', offset);
      jQuery(this).addClass('to-reveal');
    });

    let numRevealed = 0;
    jQuery('.gutenberg-styles .to-reveal').each(function() {
      if (jQuery(window).scrollTop() + (windowHeight - blockTriggerHeight) >= jQuery(this).data('offset')) {
        numRevealed++;
        const thisRef = $(this);
        setTimeout(function(){
          thisRef.removeClass('to-reveal');
          thisRef.addClass('revealed');
        }, 700 + (numRevealed * 600));
      }
    });
  }
})
